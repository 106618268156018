@import-normalize;
@import './assets/styles/styles.scss';

.App {
    min-height: 100vh;
    background: var(--background-shade);
    display: flex;
    flex-direction: column;
}

.stravaAPILogo {
    max-width: 125px;
}