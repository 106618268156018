button {
    border: 0;
    outline: 0;
    padding: 0.5rem;
    touch-action: manipulation;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

.btn {
    background: var(--color-primary);
    min-width: 125px;
    color: #fff;
    font-weight: bold;
    padding: 0.75rem 1rem;
    transition: 0.2s ease all;
    &:hover { background: var(--color-primary--hover); }
}

.btn--success {
    background: var(--color-success);
    &:hover { background: var(--color-success--hover); }
}

.btn--info {
    background: var(--color-info);
    &:hover { background: var(--color-info--hover); }
}

button:disabled {
    cursor: not-allowed;
}

.btn--warning {
    background: var(--color-warning);
    &:hover { background: var(--color-warning--hover); }
}

.btn--danger {
    background: var(--color-danger);
    &:hover { background: var(--color-danger--hover); }
}

button > * {
    pointer-events: none;
}
