.UnitToggle {
    width: auto;
    margin-left: auto;
    display: table;
    font-weight: bold;
}

.UnitToggle button {
    transition: all 0.1s ease;
    margin: -0.5rem 0.125rem;
    color: var(--text-color);
}

.UnitToggle button:hover {
    background: var(--color-primary-200);
    color: var(--color-gray--dark);
}

.UnitToggle button.active {
    /* background: var(--gradient-primary); */
    background: var(--color-primary);
    color: #fff;
}