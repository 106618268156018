.StravaConnectButton {
    background: transparent;
    display: inline-block;
    margin-top: 1.25rem;
}

.StravaConnectButton img {
    position: relative;
    left: -3px;
    height: 60px;
}