/* .ActivityHead h1 {
    margin-bottom: 0.5rem;
}

.ActivityHead__date {
    font-weight: bolder;
    margin-bottom: 0.5rem;
    display: block;
}

.ActivityHead__device {
    margin-bottom: 2rem;
}

.ActivityHead .Panel__content {
    display: flex;
    justify-content: space-between;
}

.ActivityHead__left,
.ActivityHead__right {
    max-width: 50%;
}

.Map img {
    max-height: 250px;
} */

.ActivityHead .Panel__content {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 991px) {
    .ActivityHead .Panel__content {
        text-align: center;
    }
}

/* @todo - make these more general for other links */
.ActivityHead a {
    display: block;
    font-weight: bold;
    margin-top: 1.5rem;
}