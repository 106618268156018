.SocialLinks {
    ul {
        justify-content: flex-end;

        li + li { padding-left: 1.25rem; }
    }

    a {  
        background: var(--text-color);
        color: var(--text-color--inverse);
        font-size:1em;
        border-radius: 50%;
        line-height: 1em;
        width: 1.75em;
        height: 1.75em;
        text-align: center;
        display: inline-block;
        transition: all 0.2s ease;
        transition-property: color, background-color;

        &:hover {
            background-color: var(--color-primary);
            color: #fff;
        }
    }

    svg {
        position: relative;
        top: 6px;
    }
}

@media screen and (max-width: 767px) {
    .SocialLinks {
        ul {
            justify-content: center;
        }
    }
}