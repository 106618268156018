.Profile .Panel {
    // todo - move these elsewhere and make them more general
    background: transparent;
    box-shadow: none;
}

.Profile .Panel__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.Profile__heading {
    margin: 1rem 0;
}

.Profile__location {
    display: flex;
    align-items: center;

    * { margin: 0; }
    svg { margin-right: 0.25rem; font-size: 0.8rem;}
}