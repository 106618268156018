.Setting {
    margin-top: 3rem;
}

.status {
    font-weight: 600;
}

// .bold {
//     font-weight: bold;
// }

.italic {
    font-style: italic;
}
