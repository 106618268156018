.SortIcon {
    transition: transform 0.1s ease, opacity 0.2s ease;
    position: relative;
    top: 2px;
    left: 5px;
    opacity: 1;
}

.SortIcon--hidden {
    opacity: 0;
}

th:hover {
    .SortIcon--hidden {
        opacity: 0.7;
    }
}

.SortIcon--descending {
    transform: rotate(180deg);
}