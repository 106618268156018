.Header {
    background: #fff;
    background: var(--background-color);
    // box-shadow: 0 1px 2px rgba(0,0,0,0.025);
    color: var(--text-color);
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    font-weight: 300;
    white-space: nowrap;
    user-select: none;
}

.Header__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}

.Header__brand {
    height: 100%;
    display: flex;
    align-items: center;

    img { height: 20px; }
}

[data-theme="dark"] .Header__brand img {
    filter: brightness(0) invert(1);
}
