.Select {
    position: relative;
    display: inline-flex;
}

.Select select {
    padding-right: 1.25rem;
}

.Select svg {
    position: absolute;
    right: 0.25rem;
    height: 100%;
    pointer-events: none;
}