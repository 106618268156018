.Pagination {
    list-style: none;
    display: inline-flex;
    padding: 0;
    justify-content: flex-end;
    align-items:center;
    opacity: 1;
}

.Pagination.disabled {
    opacity: 0;
}

.Pagination li {
    margin: 0 0.125rem;
    opacity: 1;
}

.Pagination li button {
    color: var(--text-color);
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
}

.Pagination li.active button,
.Pagination li.active:hover button {
    transition: none;
    /* background: var(--gradient-primary); */
    background: var(--color-primary);
    color: white;
}

.Pagination li:hover button {
    background: var(--color-primary-200);
    color: var(--color-gray--dark);
}

.Pagination li.disabled {
    opacity: 0;
}

.Pagination button:disabled {
    cursor: default;
}