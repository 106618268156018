.Navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
}

nav {
    ul {
        list-style: none;
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.NavMenu {  
    a, .a {
        display: block;
        padding: 1rem;
        cursor: pointer;
        font-weight: 600;
    }
}
  
.NavMenu {
    font-weight: 700;
}
  
.NavMenu__menuItem {
    display: block;
    position: relative;
    border: 1px solid transparent;

    &:hover {
        border-color: var(--color-primary--light);
        background-color: var(--color-primary--light);
        border-top: 0;
        border-bottom: 0;

        .NavMenu__subMenu { display: block; }
    }
}
    
.NavMenu__subMenu {
    display: none;
    position: absolute;
    right: -1px;
    top: 64px;
    min-width: 150px;
    max-width: 100%;
    background: var(--background-color);
    z-index: 2;

    a, .a {
        position: relative;
        color: var(--text-color);
        &.active { color: var(--color-primary); }
    }
}
    
.NavMenu__subMenuItem {
    &:hover {
        background: var(--color-primary--light);

        a, .a { 
            color: var(--color-gray--dark); 
            &.active { color: var(--color-primary); }
        }
    }
}

.NavMenu .ProfileImage img {
    height: 35px;
    width: 35px;
    height: 100%;
}

.NavMenu__menuItem:hover {
    .NavMenu__subMenu {
        display: block;
        border: 1px solid var(--color-primary--light);
        border: 1px solid var(--border-color);
        border-right: 2px;
        border-top: 0;
    }
}

.logout {
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    display: block;

    // svg {
    //     position: absolute;
    //     top: 15px;
    //     font-size: 1.2rem;
    //     margin-left: 0.5rem;
    // }
}

.NavMenu__subMenu {
    svg {
        position: absolute;
        top: 15px;
        font-size: 1.2rem;
        // margin-left: 0.5rem;
        // margin-right: 0.5rem;
        left: 1rem;
    }
    span {
        margin-left: 1.75rem;
    }
}
