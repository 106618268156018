.SiteFooter {
    padding: 1.5rem 0;

    .row + .row {
        padding-top: 1.5rem;
    }

        // .no-padding {
        //     margin-left: -2rem;
        //     margin-right: -2rem;
        // }
}

// @todo - move these elsewhere
.col-start {
    text-align: left;
}
.col-center {
    text-align: center;
}
.col-end {
    text-align: right;
}

@media screen and (max-width: 767px) {
    .SiteFooter {
        padding: 2rem 0;

        [class*="col-"] + [class*="col-"] {
            padding-top: 1.5rem;
        }
    }

    .col-start,
    .col-center,
    .col-end {
        text-align: center;
    }
}

.stranalyticsLogo {
    height: 20px;
}

[data-theme="dark"] .stranalyticsLogo {
    filter: brightness(0) invert(1);
}