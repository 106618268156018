table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  // box-shadow: 0px 8px 24px rgba(13,13,18,0.1);
  border-radius: var(--border-radius);
  overflow: auto;
}

thead, tbody {
  border-collapse: separate;
}

thead {
  background: var(--th-background-color);
  color: var(--th-color);
}

tbody tr:nth-of-type(even) {
  background: var(--tr-background-color);
}

tbody tr:nth-of-type(odd) {
  background: var(--tr-background-color--alt);
}

th, td {
  border-collapse: separate;
  border-color: white;
  padding: 0.75rem;
}

th {
  padding: 0.9rem 0.75rem;
  border-bottom: 1px solid var(--border-color);
}

th + th {
  border-left: 1px solid var(--border-color);
}
