.SortableTable table {
    overflow: hidden;
    border: 1px solid var(--table-border-color);
}

.SortableTable td {
    white-space: nowrap;
}

.SortableTable th {
    transition: color 0.2s ease, opacity 0.2s ease;
    white-space: nowrap;
    user-select: none;
}

.SortableTable th.sorted 
/* .SortableTable th:first-of-type  */
{
    /* color: var(--color-primary); */
    background: var(--gradient-primary);
}

.SortableTable th:hover {
    cursor: pointer;
}

.SortableTable th:not(.sorted):hover {
    opacity: 0.7;
}

.SortableTable a {
    color: inherit;
}

.SortableTable__controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.SortableTable__entries {
    display: inline-block;
    margin: 0;
    white-space: nowrap;
}

.SortableTable__footer {
    padding: 1rem 0;
    border-radius: 0 0 5px 5px;
    background: transparent;
    color: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.tableWrapper {
    overflow: scroll;
}

@media screen and (max-width: 767px) {
    .SortableTable__footer {
        flex-direction: column-reverse;
        align-items: flex-end;
    }

    .SortableTable__entries {
        display: block;
        text-align: right;
    }

    .SortableTable__footer ul.Pagination {
        margin: 1.5rem 0;
    }
}
