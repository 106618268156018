.Stat {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* @todo - make this color a variable */
.Stat h4 {
    margin: 0;
    white-space: nowrap;
}

.Stat__value {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--color-primary);
}

