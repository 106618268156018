/** @todo - share these styles with SortableTable as they are duplicated! **/

.Table table {
    overflow: hidden;
    border: 1px solid var(--table-border-color);
}

.Table td {
    white-space: nowrap;
}

.Table th {
    transition: color 0.2s ease, opacity 0.2s ease;
    white-space: nowrap;
}

.Table th.sorted 
/* .SortableTable th:first-of-type  */
{
    /* color: var(--color-primary); */
    background: var(--gradient-primary);
}

.Table a {
    color: inherit;
}

.Table__controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.Table__entries {
    display: inline-block;
    margin: 0;
    white-space: nowrap;
}

.Table__footer {
    padding: 0;
    border-radius: 0 0 5px 5px;
    background: transparent;
    color: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.tableWrapper {
    overflow: scroll;
}

@media screen and (max-width: 767px) {
    .Table__footer {
        flex-direction: column-reverse;
        align-items: flex-end;
    }

    .Table__entries {
        display: block;
        text-align: right;
    }

    .Table__footer ul.Pagination {
        margin: 1.5rem 0;
    }
}
