// @todo - is this redundant? If so delete

.CenterLayout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    max-width: 800px;
    margin: 0 auto;
    padding: 1rem;
    text-align: left;
}