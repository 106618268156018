.flow > * + * {
    margin-top: var(--flow-space, 1rem);
}

.screenReaderText {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal;
}

// .row {
//     margin-bottom: 1rem;
// }

[class*='col-'] {
    padding: 0 8px;
}
