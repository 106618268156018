.TableSearch {
    width: 250px;
    position: relative;
}

/* 
 * @todo - move these into input styles 
 * @todo - make input specific CSS vars
 */

.TableSearch label {
    color: var(--color-gray--dark);
    position: absolute;
    height: 100%;
    line-height: 1.25;
    left: 0;
    padding: 0.5rem;
    pointer-events: none;
}

.TableSearch input:focus {
    border-color: var(--color-primary);
}

.TableSearch input:focus + label {
    visibility: hidden;
}

.TableSearch input + label {
    visibility: hidden;
}

.TableSearch input[value=""] + label {
    visibility: visible;
}