input, button, select {
    font-family: inherit;
    font-size: inherit;
    border-radius: var(--border-radius);
}

input, select {
    min-width: 0;
    width: 100%;
    border: 1px solid;
    outline: 0;
    padding: 0.5rem;
}

input {
    background: #fff;
    // background: var(--input-background-color);
    color: var(--color-gray--dark);
    // color: var(--input-color);
    // border: 1px solid var(--input-border-color);
}

select {
    appearance: none;
    width: auto;
    background: var(--background-color--inverse);
    color: var(--text-color--inverse);
    cursor: pointer;
}