.Hero {
    background-repeat: no-repeat;
    background-position: center;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: white;
    position: relative;
    flex-grow: 1;
}

.Hero:before {
    background-color: rgba(0,0,0,0.85);
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
}

.Hero h1 {
    margin-top: 0;
    color: var(--color-primary);
}

.Hero p {
    font-size: 1.5rem;
}

/* .Hero__panel {
    padding: 4rem;
    z-index: 1;
} */

.Hero__panel {
    z-index: 2!important;
}

.Hero > * {
    z-index: 1;
}

@media (max-width: 767px) {
    .Hero__panel {
        text-align: center;
        padding: 0;
    }
}
