.SubFooterMenu {
    padding-bottom: 1rem;
    ul {
        justify-content: center;
    }

    li a {
        color: var(--text-color);
        transition: 0.1s ease all;

        &:hover {
            color: var(--color-primary);
            text-decoration: underline;
        }
    }

    li + li {
        padding-left: 0.75rem;

        &:before {
            content: '|';
            font-size: 0.8rem;
            padding-right: 0.75rem;
        }
    }
}

@media screen and (max-width: 767px) {
    .SubFooterMenu {
        padding-bottom: 0;
    }
}
