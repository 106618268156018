:root {
    // Colors
    --color-primary-100: #FDECD0;
    --color-primary-200: #FCD5A2;
    --color-primary-300: #F8B573;
    --color-primary-400: #F19750;
    --color-primary-500: #E9681A;

    --color-primary: var(--color-primary-500);
    --color-primary--hover: var(--color-primary-400);
    --color-primary--light: var(--color-primary-200);

    --color-secondary-100: #FDF3D0;
    --color-secondary-200: #FCE5A2;
    --color-secondary-300: #F8D073;
    --color-secondary-400: #F1BB4F;
    --color-secondary-500: #E99B19;

    --color-secondary: var(--color-secondary-500);
    --color-secondary--hover: var(--color-seconary-400);

    --color-success-100: #E6FCDA;
    --color-success-200: #C8FAB6;
    --color-success-300: #A0F28F;
    --color-success-400: #7AE670;
    --color-success-500: #44D644;

    --color-success: var(--color-success-500);
    --color-success--hover: var(--color-success-400);

    // Tailwind
    --color-success: #38a169;
    --color-success--hover: #48bb78;

    --color-info-100: #CBF2FE;
    --color-info-200: #98E1FE;
    --color-info-300: #65C9FD;
    --color-info-400: #3EB0FB;
    --color-info-500: #0089F9;

    --color-info: var(--color-info-500);
    --color-info--hover: var(--color-info-400);

    --color-warning-100: #FFFBCD;
    --color-warning-200: #FFF69B;
    --color-warning-300: #FFF069;
    --color-warning-400: #FFEA43;
    --color-warning-500: #FFE105;

    --color-warning: var(--color-warning-500);
    --color-warning--hover: var(--color-warning-400);

    --color-danger-100: #FEE8D2;
    --color-danger-200: #FECAA6;
    --color-danger-300: #FDA67A;
    --color-danger-400: #FB8358;
    --color-danger-500: #F94A22;

    --color-danger: var(--color-danger-500);
    --color-danger--hover: var(--color-danger-400);

    // Tailwind
    --color-danger: #e53e3e;
    --color-danger--hover: #f56565;

    --color-gray-100: #EFEFF4;
    --color-gray-200: #E0E0E9;
    --color-gray-300: #B3B3BE;
    --color-gray-400: #74747E;
    --color-gray-500: #242428;
    --color-gray-600: #1A1A22;
    --color-gray-700: #12121C;
    --color-gray-800: #0B0B17;
    --color-gray-900: #060613;

    --color-gray--light: var(--color-gray-100);
    --color-gray--lighter-alt: var(--color-gray-200);
    --color-gray--lighter: var(--color-gray-300);
    --color-gray-mid: var(--color-gray-400);
    --color-gray--darker: var(--color-gray-500);
    --color-gray--dark: var(--color-gray-700);
    
    // Gradients
    --gradient-primary: linear-gradient(90deg, #e9681a, #e99b19);

    // Text
    --text-color: #242428;
    --heading-color: #242428;
    --text-color--inverse: #fff;
    --heading-color--inverse: #fff;

    // Background
    --background-color: #fff;
    --background-shade: var(--color-gray--light);
    --background-color--inverse: var(--color-gray-500);
    --background-shade--inverse: var(--color-gray-700);

    // Tables
    --table-border-color: transparent;
    --th-color: #fff;
    --th-background-color: var(--color-gray--darker);
    --tr-color: var(--text-color);
    --tr-background-color: var(--color-gray-200);
    --tr-background-color--alt: var(--color-gray-100);
    --highlight-color: #fedbcc;

    // Borders
    --border-radius: 5px;
    --border-color: #f0f0f5;

    // ::selection
    --selection-color: var(--highlight-color);
}

[data-theme="dark"] {
    // Background colors
    --background-color: var(--color-gray-900);
    --background-shade: var(--color-gray-700);
    --background-color--inverse: #fff;
    --background-shade--inverse: var(--color-gray--light);

    // @todo - delete this or replace
    --background-shade: var(--color-gray-700);
    --background-color: var(--color-gray-600);

    // Text
    --text-color: #fff;
    --heading-color: #fff;
    --text-color--inverse: #242428;
    --heading-color--inverse: #242428;

    // Input
    --input-color: var(--text-color--inverse);
    --input-background-color: var(--background-color--inverse);
    --input-border-color: var(--border-color);

    // Tables
    --table-border-color: var(--border-color);
    --th-color: #fff;
    --th-background-color: var(--color-gray--dark);

    //todo - delete this or replace
    --th-background-color: var(--color-gray-900);

    --tr-color: var(--text-color);
    --tr-background-color: var(--color-gray-700);
    --tr-background-color--alt: var(--color-gray-400);
    --highlight-color: #373737;
    --highlight-color: var(--color-secondary);

    // Borders
    --border-color: rgba(255,255,255,0.1);
}
