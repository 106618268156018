.Panel {
    height: 100%;
    background: var(--background-color);
    border-radius: var(--border-radius);
    border: 1px solid var(--border-color);
    box-shadow: 0 10px 24px rgba(13,13,18,0.15);
    overflow: hidden;
    margin-bottom: 1rem;
}

.Panel header {
    width: 100%;
    padding: 1.5rem 1.5rem 0 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1rem;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    margin-bottom: 1.5rem;
}

.Panel header > * {
    margin: 0;
}

.Panel__content {
    padding: 1.5rem;
    height: 100%;

    @media screen and (max-width: 767px) {
        padding: 1rem;
    }
}

.Panel.closed .Panel__content {
    visibility: hidden;
    height: 0;
    margin: 0;
    padding: 0;
}

.Panel.closed .UnitToggle {
    display: none;
}

.Panel__control {
    display: flex;
    justify-content: flex-end;
}

.Panel__settings {
    margin-left: auto;
    font-size: 1.25rem;
}
