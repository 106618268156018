@supports not (display: grid) {    
    .ActivityStats .Panel__content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    
    .ActivityStats .Stat {
        width: calc(20% - 1.5rem);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 2rem;
    }
    
    @media (max-width: 999px) {
        .ActivityStats .Stat {
            width: calc(33.33% - 1.5rem);
        }
    }
    
    @media (max-width: 767px) {
        .ActivityStats .Stat {
            width: calc(50% - 1.5rem);
        }
    }
}

@supports (display: grid) {
    .ActivityStats .Panel__content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        grid-auto-rows: 1fr;
        grid-gap: 2rem;
    }
}
