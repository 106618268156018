.ActivityNavigation {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    padding: 0;
}

.prevActivity,
.nextActivity {
    font-weight: bold;
    background: transparent;
    transition: color 0.2s ease;
    color: inherit;
}

.prevActivity:hover,
.nextActivity:hover {
    color: var(--color-primary);
}

.prevActivity svg,
.nextActivity svg {
    position: relative;
    top: 2px;
    font-size: 0.9rem;
}

.prevActivity svg {
    margin-right: 0.125rem;
    margin-left: 0.5rem;
}

.nextActivity svg {
    margin-left: 0.125rem;
    margin-right: 0.5rem;
}

@media (max-width: 767px) {
    .ActivityNavigation button span {
        display: none;
    }

    .ActivityNavigation svg {
        font-size: 1.5rem;
    }

    .prevActivity svg {
        margin-left: 0;
        font-size: 1.15rem;
    }

    .nextActivity svg {
        margin-right: 0;
        font-size: 1.15rem;
    }
}