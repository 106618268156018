@import '_reset.scss';
@import 'vendor/bootstrap-grid.min.scss';
@import '_variables.scss';
@import '_utilities.scss';
@import '_base.scss';
@import '_buttons.scss';
@import '_inputs.scss';
@import '_links.scss';
@import '_lists.scss';
@import '_tables.scss';

/* @todo - move this elsewhere. Rename? (flexBlock?) */
.dashBlock {
  margin-bottom: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 100%;
}

/* @todo - move elswhere or delete */

/* .dashBlock h1,
.dashBlock h2,
.dashBlock h3,
.dashBlock h4,
.dashBlock h5,
.dashBlock h6 {
  margin: 0;
}

.panelRow {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
} */

/* .page {
  flex-grow: 1;
  display: flex;
  background: var(--color-gray--light);
} */

/* .page, .container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
} */
/* 
.container,
.Dashboard {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.pageCenter {
  margin: auto;
} */

/* .pageTitle {
  margin: 0 0 0 1.5rem;
} */

.lead {
  font-size: 1.2rem;
}

.inlineButtons {
  button + button {
    margin-left: 1rem;
  }
}

