* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  font-family: 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  background: var(--background-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

blockquote {
  background: linear-gradient(to right, var(--color-primary--light), transparent);
  border-left: 6px solid var(--color-primary);
  margin: 1.5em 10px;
  margin-left: 0;
  margin-bottom: 0;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
  border-radius: 2px;
}

blockquote:before {
  color: var(--color-primary);
  content: open-quote;
  font-size: 2em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

hr {
  border: 1px solid var(--border-color);
  padding: 0;
  margin: 0;
}

footer {
  padding:2rem 0;
}

/* h1 {
  font-size: 2.5rem;
  margin-bottom: 1em;
} */

/* @media (max-width: 767px) {
  h1 { font-size: 2rem; }
} */

/* h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
} */

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  font-family: 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}

::selection { background: var(--selection-color); }

.page {
  flex: 1;
  display: flex;
  flex-direction: column;
}