.lds-dual-ring {
  display: inline-block;
  width: 1rem;
  height: 1rem;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  margin: 0 auto;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.SyncingSpinner svg {
  position: relative;
  top: 2px;
  animation: lds-dual-ring 1.2s linear infinite;
}
