.UserStats .Panel {
    background: transparent;
    box-shadow: none;
}

@supports not (display: grid) {
    .UserStats {
        width: 100%;
        display: flex;
        height: 100%;
        flex-grow: 1;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    
    .UserStats .Stat {
        width: calc(25% - 1.5rem);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    @media (max-width: 999px) {
        .UserStats .Stat {
            width: calc(33.33% - 1.5rem);
        }
    }
    
    @media (max-width: 767px) {
        .UserStats .Stat {
            width: calc(50% - 1.5rem);
        }
    }
}

@supports (display: grid) {
    .UserStats .Panel__content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-auto-rows: 1fr;
        grid-gap: 2rem;
    }
}
