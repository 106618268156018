.BackButton {
    display: flex;
    align-items: center;
    font-weight: bold;
    transition: color 0.2s ease;
    background: transparent;
    padding: 0;
    color: var(--text-color);
}

.BackButton:hover {
    color: var(--color-primary);
}

.BackButton svg {
    position: relative;
    margin-right: 0.5rem;
    top: 1px;
    font-size: 1.3rem;
}
@media (max-width: 767px) {
    .BackButton svg {
        font-size: 1.6rem;
    }
}